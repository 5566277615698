$color_1: #222;
$color_2: inherit;
$color_3: #21759b;
$color_4: #333;
$color_5: #666;
$color_6: #767676;
$color_7: #fff;
$color_8: #000;
$color_9: rgba(255, 255, 255, 0.6);
$color_10: #eee;
$color_11: #aaa;
$color_12: #777;
$font-family_1: sans-serif;
$font-family_2: monospace, monospace;
$font-family_3: "Libre Franklin", "Helvetica Neue", helvetica, arial, sans-serif;
$font-family_4: "Courier 10 Pitch", Courier, monospace;
$font-family_5: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font-family_6: Tahoma, Arial, sans-serif;
$font-family_7: "PingFang TC", "Helvetica Neue", Helvetica, STHeitiTC-Light, Arial, sans-serif;
$font-family_8: "PingFang SC", "Helvetica Neue", Helvetica, STHeitiSC-Light, Arial, sans-serif;
$font-family_9: "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
$font-family_10: Arial, sans-serif;
$font-family_11: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family_12: "Arial Hebrew", Arial, sans-serif;
$font-family_13: "Hiragino Kaku Gothic Pro", Meiryo, sans-serif;
$font-family_14: "Apple SD Gothic Neo", "Malgun Gothic", "Nanum Gothic", Dotum, sans-serif;
$font-family_15: "Sukhumvit Set", "Helvetica Neue", Helvetica, Arial, sans-serif;
$background-color_1: transparent;
$background-color_2: #eee;
$background-color_3: #f1f1f1;
$background-color_4: #222;
$background-color_5: #ddd;
$background-color_6: #bbb;
$background-color_7: #fafafa;
$background-color_8: rgba(34, 34, 34, 0.5);
$background-color_9: rgba(34, 34, 34, 0.8);
$background-color_10: #fff;
$background-color_11: #767676;
$background-color_12: #333;
$background-color_13: #aaa;
$border-color_1: #333;
$border-color_2: rgba(255, 255, 255, 0.8);
$border-color_3: #bbb;
$border-color_4: #eee;
$border-color_5: transparent transparent #bbb;
$border-color_6: transparent transparent #fff;
$border-bottom-color_1: rgba(0, 0, 0, 0);

html {
  font-family: $font-family_1;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  [type="button"] {
    -webkit-appearance: button;
  }
}

body {
  margin: 0;
  color: $color_4;
  font-family: $font-family_3;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.66;
  background: #fff;

  &:not(.title-tagline-hidden) {
    .site-branding-text {
      display: inline-block;
      max-width: 100%;
      vertical-align: middle;
    }
  }

  .small-header-page {
    .entry-header {
      padding: 1em 0;
      margin-left: auto;
      margin-right: auto;
      padding-top: 0;
    }

    .entry-content {
      margin-left: auto;
      margin-right: auto;
    }

    #comments {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

article {
  display: block;
}

aside {
  display: block;
}

footer {
  display: block;
}

header {
  display: block;
}

nav {
  display: block;
}

section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
  clear: both;
  line-height: 1.4;
  margin: 0 0 0.75em;
  padding: 1.5em 0 0;
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 300;

  &:first-child {
    padding-top: 0;
  }
}

figcaption {
  display: block;
}

figure {
  display: block;
  margin: 1em 0;
}

main {
  display: block;
}

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  background-color: $background-color_6;
  border: 0;
  height: 1px;
  margin-bottom: 1.5em;
}

pre {
  font-family: $font-family_2;
  font-size: 1em;
  background: #eee;
  font-family: $font-family_4;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

a {
  background-color: $background-color_1;
  -webkit-text-decoration-skip: objects;
  color: $color_1;
  text-decoration: none;

  &:active {
    outline-width: 0;
    color: $color_8;
    outline: 0;
  }

  &:hover {
    outline-width: 0;
    color: $color_8;
    outline: 0;

    .nav-title {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }
  }

  &:focus {
    outline: thin dotted;

    .nav-title {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }
  }

  .nav-title {
    -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    text-decoration: none;
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
  }
}

abbr[title] {
  border-bottom: 1px #767676 dotted;
  text-decoration: none;
}

b {
  font-weight: inherit;
  font-weight: 700;
}

strong {
  font-weight: inherit;
  font-weight: 700;
}

code {
  font-family: $font-family_2;
  font-size: 1em;
  font-family: $font-family_5;
  font-size: 15px;
  font-size: 0.9375rem;
}

kbd {
  font-family: $font-family_2;
  font-size: 1em;
  font-family: $font-family_5;
  font-size: 15px;
  font-size: 0.9375rem;
}

samp {
  font-family: $font-family_2;
  font-size: 1em;
}

dfn {
  font-style: italic;
  font-style: italic;
}

mark {
  background-color: $background-color_2;
  color: $color_1;
  background: #eee;
  text-decoration: none;
}

small {
  font-size: 80%;
}

sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  bottom: -0.25em;
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
}

audio {
  display: inline-block;

  &:not([controls]) {
    display: none;
    height: 0;
  }

  &:focus {
    outline: thin dotted;
  }
}

video {
  display: inline-block;
  height: auto;
  max-width: 100%;

  &:focus {
    outline: thin dotted;
  }
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
}

svg {
  &:not(:root) {
    overflow: hidden;
  }
}

button {
  font-family: $font-family_1;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  color: $color_4;
  font-family: $font-family_3;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.66;
  background-color: $background-color_4;
  border: 0;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $color_7;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1;
  padding: 1em 2em;
  text-shadow: none;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;

  &::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  &:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
}

input {
  font-family: $font-family_1;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  color: $color_4;
  font-family: $font-family_3;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.66;

  + {
    button {
      padding: 0.75em 2em;
    }

    input[type="button"] {
      padding: 0.75em 2em;
    }

    input[type="submit"] {
      padding: 0.75em 2em;
    }
  }
}

optgroup {
  font-family: $font-family_1;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

select {
  font-family: $font-family_1;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  text-transform: none;
  color: $color_4;
  font-family: $font-family_3;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.66;
  border: 1px solid #bbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  height: 3em;
  max-width: 100%;
}

textarea {
  font-family: $font-family_1;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: auto;
  color: $color_4;
  font-family: $font-family_3;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.66;
  color: $color_5;
  background: #fff;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #bbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  padding: 0.7em;
  width: 100%;

  &:focus {
    color: $color_1;
    border-color: $border-color_1;
  }
}

[type="reset"] {
  -webkit-appearance: button;

  &::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  &:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
}

[type="submit"] {
  -webkit-appearance: button;

  &::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  &:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
}

[type="button"] {
  &::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  &:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
}

fieldset {
  border: 1px solid #bbb;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
  margin-bottom: 1em;
}

legend {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: $color_2;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

[type="checkbox"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

[type="number"] {
  &::-webkit-inner-spin-button {
    height: auto;
  }

  &::-webkit-outer-spin-button {
    height: auto;
  }
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;

  &:focus {
    background-color: $background-color_3;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: $color_3;
    display: block;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 700;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
  }
}

.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.clear {
  &:before {
    content: "";
    display: table;
    table-layout: fixed;
  }

  &:after {
    content: "";
    display: table;
    table-layout: fixed;
    clear: both;
  }
}

.entry-content {
  &:before {
    content: "";
    display: table;
    table-layout: fixed;
  }

  &:after {
    content: "";
    display: table;
    table-layout: fixed;
    clear: both;
  }

  a {
    -webkit-box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
    box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;

    &:focus {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }

    &:hover {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }

    img {
      -webkit-box-shadow: 0 0 0 8px #fff;
      box-shadow: 0 0 0 8px #fff;
    }
  }

  blockquote.alignleft {
    color: $color_5;
    font-size: 13px;
    font-size: 0.8125rem;
    width: 48%;
  }

  blockquote.alignright {
    color: $color_5;
    font-size: 13px;
    font-size: 0.8125rem;
    width: 48%;
  }

  .more-link {
    &:before {
      content: "";
      display: block;
      margin-top: 1.5em;
    }
  }

  .wp-smiley {
    border: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
  }
}

.entry-footer {
  &:before {
    content: "";
    display: table;
    table-layout: fixed;
  }

  &:after {
    content: "";
    display: table;
    table-layout: fixed;
    clear: both;
  }

  a {
    -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    text-decoration: none;
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;

    &:focus {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }

    &:hover {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }
  }

  .cat-links {
    a {
      -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
      box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
      text-decoration: none;
      -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
      color: $color_4;

      &:focus {
        color: $color_8;
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      }

      &:hover {
        color: $color_8;
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      }
    }

    display: block;
    font-size: 11px;
    font-size: 0.6875rem;
    font-weight: 800;
    letter-spacing: 0.1818em;
    padding-left: 2.5em;
    position: relative;
    text-transform: uppercase;

    + {
      .tags-links {
        margin-top: 1em;
      }
    }

    .icon {
      color: $color_6;
      left: 0;
      margin-right: 0.5em;
      position: absolute;
      top: 2px;
    }
  }

  .tags-links {
    a {
      -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
      box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
      text-decoration: none;
      -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
      color: $color_4;

      &:focus {
        color: $color_8;
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      }

      &:hover {
        color: $color_8;
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      }
    }

    display: block;
    font-size: 11px;
    font-size: 0.6875rem;
    font-weight: 800;
    letter-spacing: 0.1818em;
    padding-left: 2.5em;
    position: relative;
    text-transform: uppercase;

    .icon {
      color: $color_6;
      left: 0;
      margin-right: 0.5em;
      position: absolute;
      top: 2px;
    }
  }

  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  margin-top: 2em;
  padding: 2em 0;

  .edit-link {
    display: inline-block;

    a.post-edit-link {
      background-color: $background-color_4;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: $color_7;
      display: inline-block;
      font-size: 14px;
      font-size: 0.875rem;
      font-weight: 800;
      margin-top: 2em;
      padding: 0.7em 2em;
      -webkit-transition: background-color 0.2s ease-in-out;
      transition: background-color 0.2s ease-in-out;
      white-space: nowrap;

      &:hover {
        background-color: $background-color_11;
      }

      &:focus {
        background-color: $background-color_11;
      }
    }
  }
}

.comment-content {
  &:before {
    content: "";
    display: table;
    table-layout: fixed;
  }

  &:after {
    content: "";
    display: table;
    table-layout: fixed;
    clear: both;
  }

  a {
    -webkit-box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
    box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;

    &:focus {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }

    &:hover {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }

    img {
      -webkit-box-shadow: 0 0 0 8px #fff;
      box-shadow: 0 0 0 8px #fff;
    }
  }

  .wp-smiley {
    border: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
  }
}

.site-header {
  &:before {
    content: "";
    display: table;
    table-layout: fixed;
  }

  &:after {
    content: "";
    display: table;
    table-layout: fixed;
    clear: both;
  }

  .menu-scroll-down {
    display: none;
  }

  background-color: $background-color_7;
  position: relative;
}

.site-content {
  &:before {
    content: "";
    display: table;
    table-layout: fixed;
  }

  &:after {
    content: "";
    display: table;
    table-layout: fixed;
    clear: both;
  }

  padding: 2.5em 0 0;

  .wp-playlist-light {
    border-color: $border-color_4;
    color: $color_1;

    .wp-playlist-current-item {
      .wp-playlist-item-album {
        color: $color_4;
      }

      .wp-playlist-item-artist {
        color: $color_6;
      }
    }

    .wp-playlist-item {
      border-bottom: 1px dotted #eee;
      -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
      transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;

      &:hover {
        border-bottom-color: $border-bottom-color_1;
        background-color: $background-color_11;
        color: $color_7;

        a {
          color: $color_7;
        }
      }

      &:focus {
        border-bottom-color: $border-bottom-color_1;
        background-color: $background-color_11;
        color: $color_7;

        a {
          color: $color_7;
        }
      }
    }

    a.wp-playlist-caption {
      &:hover {
        color: $color_7;
      }
    }
  }

  .wp-playlist-dark {
    background: #222;
    border-color: $border-color_1;

    .mejs-container {
      .mejs-controls {
        background-color: $background-color_12;
      }
    }

    .wp-playlist-caption {
      color: $color_7;
    }

    .wp-playlist-current-item {
      .wp-playlist-item-album {
        color: $color_10;
      }

      .wp-playlist-item-artist {
        color: $color_11;
      }
    }

    .wp-playlist-playing {
      background-color: $background-color_12;
    }

    .wp-playlist-item {
      border-bottom: 1px dotted #555;
      -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
      transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;

      &:hover {
        border-bottom-color: $border-bottom-color_1;
        background-color: $background-color_13;
        color: $color_1;

        a {
          color: $color_1;
        }
      }

      &:focus {
        border-bottom-color: $border-bottom-color_1;
        background-color: $background-color_13;
        color: $color_1;

        a {
          color: $color_1;
        }
      }
    }

    a.wp-playlist-caption {
      &:hover {
        color: $color_1;
      }
    }
  }

  .wp-playlist {
    padding: 0.625em 0.625em 0.3125em;
  }

  .wp-playlist-current-item {
    .wp-playlist-item-title {
      font-weight: 700;
    }

    .wp-playlist-item-album {
      font-style: normal;
    }

    .wp-playlist-item-artist {
      font-size: 10px;
      font-size: 0.625rem;
      font-weight: 800;
      letter-spacing: 0.1818em;
      text-transform: uppercase;
    }
  }

  .wp-playlist-item {
    padding: 0 0.3125em;
    cursor: pointer;

    &:last-of-type {
      border-bottom: none;
    }

    a {
      padding: 0.3125em 0;
      border-bottom: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: transparent;

      &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
        background: transparent;
      }

      &:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
        background: transparent;
      }
    }
  }

  .wp-playlist-item-length {
    top: 5px;
  }
}

.site-footer {
  &:before {
    content: "";
    display: table;
    table-layout: fixed;
  }

  &:after {
    content: "";
    display: table;
    table-layout: fixed;
    clear: both;
  }

  .widget-area {
    a {
      -webkit-box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
      box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
      -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;

      &:focus {
        color: $color_8;
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      }

      &:hover {
        color: $color_8;
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      }
    }

    ul {
      li {
        a {
          -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
          -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
          box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
          box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
          text-decoration: none;
          text-decoration: none;
          -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
          -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
          transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
          transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
          transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
          transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
          transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
          transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
        }
      }
    }

    padding-bottom: 2em;
    padding-top: 2em;
  }

  border-top: 1px solid #eee;

  .wrap {
    padding-bottom: 1.5em;
    padding-top: 2em;
  }
}

.nav-links {
  &:before {
    content: "";
    display: table;
    table-layout: fixed;
  }

  &:after {
    content: "";
    display: table;
    table-layout: fixed;
    clear: both;
  }

  .nav-previous {
    .nav-title {
      .nav-title-icon-wrapper {
        margin-right: 0.5em;
      }
    }
  }

  .nav-next {
    .nav-title {
      .nav-title-icon-wrapper {
        margin-left: 0.5em;
      }
    }
  }
}

.pagination {
  &:before {
    content: "";
    display: table;
    table-layout: fixed;
  }

  &:after {
    content: "";
    display: table;
    table-layout: fixed;
    clear: both;
  }

  a {
    -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    text-decoration: none;
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;

    &:not(.prev) {
      &:not(.next) {
        &:focus {
          color: $color_8;
          -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
          box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        }

        &:hover {
          color: $color_8;
          -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
          box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        }
      }
    }
  }

  border-top: 1px solid #eee;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 800;
  padding: 2em 0 3em;
  text-align: center;

  .icon {
    width: 0.666666666em;
    height: 0.666666666em;
  }
}

.comment-author {
  &:before {
    content: "";
    display: table;
    table-layout: fixed;
  }

  &:after {
    content: "";
    display: table;
    table-layout: fixed;
    clear: both;
  }

  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 0.4em;
  position: relative;
  z-index: 2;

  .avatar {
    height: 50px;
    left: -65px;
    position: absolute;
    width: 50px;
  }

  .says {
    display: none;
  }
}

.widget-area {
  &:before {
    content: "";
    display: table;
    table-layout: fixed;
  }

  &:after {
    content: "";
    display: table;
    table-layout: fixed;
    clear: both;
  }

  .gallery-item {
    a {
      -webkit-box-shadow: none;
      box-shadow: none;
      background: none;
      display: inline-block;
      max-width: 100%;

      &:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
        background: none;
        display: inline-block;
        max-width: 100%;
      }

      &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
        background: none;
        display: inline-block;
        max-width: 100%;
      }
    }
  }
}

.widget {
  padding-bottom: 3em;

  &:before {
    content: "";
    display: table;
    table-layout: fixed;
  }

  &:after {
    content: "";
    display: table;
    table-layout: fixed;
    clear: both;
  }

  a {
    -webkit-box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
    box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;

    &:focus {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }

    &:hover {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }

    img {
      -webkit-box-shadow: 0 0 0 8px #fff;
      box-shadow: 0 0 0 8px #fff;
    }
  }

  .widget-title {
    a {
      -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
      box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
      text-decoration: none;
      -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;

      &:focus {
        color: $color_8;
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      }

      &:hover {
        color: $color_8;
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      }
    }
  }

  ul {
    li {
      a {
        -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
        box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
        text-decoration: none;
        -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
        transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
        transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
        transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;

        &:focus {
          color: $color_8;
          -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
          box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        }

        &:hover {
          color: $color_8;
          -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
          box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        }
      }

      border-bottom: 1px solid #ddd;
      border-top: 1px solid #ddd;
      padding: 0.5em 0;

      ul {
        margin: 0 0 -1px;
        padding: 0;
        position: relative;
      }

      li {
        border: 0;
        padding-left: 24px;
        padding-left: 1.5rem;
      }
    }

    list-style: none;
    margin: 0;
  }

  select {
    width: 100%;
  }

  ol {
    li {
      border-bottom: 1px solid #ddd;
      border-top: 1px solid #ddd;
      padding: 0.5em 0;
    }
  }

  &:not(.widget_tag_cloud) {
    ul {
      li {
        + {
          li {
            margin-top: -1px;
          }
        }
      }
    }
  }

  .post-date {
    font-size: 0.81em;
  }

  .rss-date {
    font-size: 0.81em;
  }

  .tagcloud {
    a {
      border: 1px solid #ddd;
      -webkit-box-shadow: none;
      box-shadow: none;
      display: block;
      padding: 4px 10px 5px;
      position: relative;
      -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
      transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
      width: auto;
      word-wrap: break-word;
      z-index: 0;

      &:hover {
        border-color: $border-color_3;
        -webkit-box-shadow: none;
        box-shadow: none;
        text-decoration: none;
      }

      &:focus {
        border-color: $border-color_3;
        -webkit-box-shadow: none;
        box-shadow: none;
        text-decoration: none;
      }
    }
  }
}

.comment-meta {
  margin-bottom: 1.5em;

  &:before {
    content: "";
    display: table;
    table-layout: fixed;
  }

  &:after {
    content: "";
    display: table;
    table-layout: fixed;
    clear: both;
  }
}

h2 {
  clear: both;
  line-height: 1.4;
  margin: 0 0 0.75em;
  padding: 1.5em 0 0;
  color: $color_5;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 300;

  &:first-child {
    padding-top: 0;
  }
}

h3 {
  clear: both;
  line-height: 1.4;
  margin: 0 0 0.75em;
  padding: 1.5em 0 0;
  color: $color_4;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 300;

  &:first-child {
    padding-top: 0;
  }
}

h4 {
  clear: both;
  line-height: 1.4;
  margin: 0 0 0.75em;
  padding: 1.5em 0 0;
  color: $color_4;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 800;

  &:first-child {
    padding-top: 0;
  }
}

h5 {
  clear: both;
  line-height: 1.4;
  margin: 0 0 0.75em;
  padding: 1.5em 0 0;
  color: $color_6;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 800;
  letter-spacing: 0.15em;
  text-transform: uppercase;

  &:first-child {
    padding-top: 0;
  }
}

h6 {
  clear: both;
  line-height: 1.4;
  margin: 0 0 0.75em;
  padding: 1.5em 0 0;
  color: $color_4;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 800;

  &:first-child {
    padding-top: 0;
  }
}

p {
  margin: 0 0 1.5em;
  padding: 0;

  > embed {
    &:only-child {
      margin-bottom: 0;
    }
  }

  > iframe {
    &:only-child {
      margin-bottom: 0;
    }
  }

  > object {
    &:only-child {
      margin-bottom: 0;
    }
  }
}

cite {
  font-style: italic;
}

em {
  font-style: italic;
}

i {
  font-style: italic;
}

blockquote {
  color: $color_5;
  font-size: 18px;
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.7;
  margin: 0;
  overflow: hidden;
  padding: 0;
  quotes: "" "";

  cite {
    display: block;
    font-style: normal;
    font-weight: 600;
    margin-top: 0.5em;
  }

  &:before {
    content: "";
  }

  &:after {
    content: "";
  }
}

address {
  margin: 0 0 1.5em;
}

tt {
  font-family: $font-family_5;
  font-size: 15px;
  font-size: 0.9375rem;
}

var {
  font-family: $font-family_5;
  font-size: 15px;
  font-size: 0.9375rem;
}

abbr {
  border-bottom: 1px dotted #666;
  cursor: help;
}

acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

ins {
  background: #eee;
  text-decoration: none;
}

big {
  font-size: 125%;
}

q {
  quotes: "â€œ" "â€" "â€˜" "â€™";
}

:focus {
  outline: none;
}

html[lang="ar"] {
  body {
    font-family: $font-family_6;
  }

  button {
    font-family: $font-family_6;
  }

  input {
    font-family: $font-family_6;
  }

  select {
    font-family: $font-family_6;
  }

  textarea {
    font-family: $font-family_6;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
  }

  h4 {
    font-weight: 700;
  }

  h5 {
    font-weight: 700;
  }

  h6 {
    font-weight: 700;
  }

  * {
    letter-spacing: 0 !important;
  }
}

html[lang="ary"] {
  body {
    font-family: $font-family_6;
  }

  button {
    font-family: $font-family_6;
  }

  input {
    font-family: $font-family_6;
  }

  select {
    font-family: $font-family_6;
  }

  textarea {
    font-family: $font-family_6;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
  }

  h4 {
    font-weight: 700;
  }

  h5 {
    font-weight: 700;
  }

  h6 {
    font-weight: 700;
  }

  * {
    letter-spacing: 0 !important;
  }
}

html[lang="azb"] {
  body {
    font-family: $font-family_6;
  }

  button {
    font-family: $font-family_6;
  }

  input {
    font-family: $font-family_6;
  }

  select {
    font-family: $font-family_6;
  }

  textarea {
    font-family: $font-family_6;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
  }

  h4 {
    font-weight: 700;
  }

  h5 {
    font-weight: 700;
  }

  h6 {
    font-weight: 700;
  }

  * {
    letter-spacing: 0 !important;
  }
}

html[lang="fa-IR"] {
  body {
    font-family: $font-family_6;
  }

  button {
    font-family: $font-family_6;
  }

  input {
    font-family: $font-family_6;
  }

  select {
    font-family: $font-family_6;
  }

  textarea {
    font-family: $font-family_6;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
  }

  h4 {
    font-weight: 700;
  }

  h5 {
    font-weight: 700;
  }

  h6 {
    font-weight: 700;
  }
}

html[lang="haz"] {
  body {
    font-family: $font-family_6;
  }

  button {
    font-family: $font-family_6;
  }

  input {
    font-family: $font-family_6;
  }

  select {
    font-family: $font-family_6;
  }

  textarea {
    font-family: $font-family_6;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
  }

  h4 {
    font-weight: 700;
  }

  h5 {
    font-weight: 700;
  }

  h6 {
    font-weight: 700;
  }

  * {
    letter-spacing: 0 !important;
  }
}

html[lang="ps"] {
  body {
    font-family: $font-family_6;
  }

  button {
    font-family: $font-family_6;
  }

  input {
    font-family: $font-family_6;
  }

  select {
    font-family: $font-family_6;
  }

  textarea {
    font-family: $font-family_6;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
  }

  h4 {
    font-weight: 700;
  }

  h5 {
    font-weight: 700;
  }

  h6 {
    font-weight: 700;
  }

  * {
    letter-spacing: 0 !important;
  }
}

html[lang="ur"] {
  body {
    font-family: $font-family_6;
  }

  button {
    font-family: $font-family_6;
  }

  input {
    font-family: $font-family_6;
  }

  select {
    font-family: $font-family_6;
  }

  textarea {
    font-family: $font-family_6;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
  }

  h4 {
    font-weight: 700;
  }

  h5 {
    font-weight: 700;
  }

  h6 {
    font-weight: 700;
  }
}

html[lang^="zh-"] {
  body {
    font-family: $font-family_7;
  }

  button {
    font-family: $font-family_7;
  }

  input {
    font-family: $font-family_7;
  }

  select {
    font-family: $font-family_7;
  }

  textarea {
    font-family: $font-family_7;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
  }

  h4 {
    font-weight: 700;
  }

  h5 {
    font-weight: 700;
  }

  h6 {
    font-weight: 700;
  }

  * {
    letter-spacing: 0 !important;
  }
}

html[lang="zh-CN"] {
  body {
    font-family: $font-family_8;
  }

  button {
    font-family: $font-family_8;
  }

  input {
    font-family: $font-family_8;
  }

  select {
    font-family: $font-family_8;
  }

  textarea {
    font-family: $font-family_8;
  }
}

html[lang="bg-BG"] {
  body {
    font-family: $font-family_9;
  }

  button {
    font-family: $font-family_9;
  }

  input {
    font-family: $font-family_9;
  }

  select {
    font-family: $font-family_9;
  }

  textarea {
    font-family: $font-family_9;
  }

  h1 {
    font-weight: 700;
    line-height: 1.2;
  }

  h2 {
    font-weight: 700;
    line-height: 1.2;
  }

  h3 {
    font-weight: 700;
    line-height: 1.2;
  }

  h4 {
    font-weight: 700;
    line-height: 1.2;
  }

  h5 {
    font-weight: 700;
    line-height: 1.2;
  }

  h6 {
    font-weight: 700;
    line-height: 1.2;
  }

  * {
    letter-spacing: 0 !important;
  }
}

html[lang="ru-RU"] {
  body {
    font-family: $font-family_9;
  }

  button {
    font-family: $font-family_9;
  }

  input {
    font-family: $font-family_9;
  }

  select {
    font-family: $font-family_9;
  }

  textarea {
    font-family: $font-family_9;
  }

  h1 {
    font-weight: 700;
    line-height: 1.2;
  }

  h2 {
    font-weight: 700;
    line-height: 1.2;
  }

  h3 {
    font-weight: 700;
    line-height: 1.2;
  }

  h4 {
    font-weight: 700;
    line-height: 1.2;
  }

  h5 {
    font-weight: 700;
    line-height: 1.2;
  }

  h6 {
    font-weight: 700;
    line-height: 1.2;
  }

  * {
    letter-spacing: 0 !important;
  }
}

html[lang="uk"] {
  body {
    font-family: $font-family_9;
  }

  button {
    font-family: $font-family_9;
  }

  input {
    font-family: $font-family_9;
  }

  select {
    font-family: $font-family_9;
  }

  textarea {
    font-family: $font-family_9;
  }

  h1 {
    font-weight: 700;
    line-height: 1.2;
  }

  h2 {
    font-weight: 700;
    line-height: 1.2;
  }

  h3 {
    font-weight: 700;
    line-height: 1.2;
  }

  h4 {
    font-weight: 700;
    line-height: 1.2;
  }

  h5 {
    font-weight: 700;
    line-height: 1.2;
  }

  h6 {
    font-weight: 700;
    line-height: 1.2;
  }

  * {
    letter-spacing: 0 !important;
  }
}

html[lang="bn-BD"] {
  body {
    font-family: $font-family_10;
  }

  button {
    font-family: $font-family_10;
  }

  input {
    font-family: $font-family_10;
  }

  select {
    font-family: $font-family_10;
  }

  textarea {
    font-family: $font-family_10;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
  }

  h4 {
    font-weight: 700;
  }

  h5 {
    font-weight: 700;
  }

  h6 {
    font-weight: 700;
  }

  * {
    letter-spacing: 0 !important;
  }
}

html[lang="hi-IN"] {
  body {
    font-family: $font-family_10;
  }

  button {
    font-family: $font-family_10;
  }

  input {
    font-family: $font-family_10;
  }

  select {
    font-family: $font-family_10;
  }

  textarea {
    font-family: $font-family_10;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
  }

  h4 {
    font-weight: 700;
  }

  h5 {
    font-weight: 700;
  }

  h6 {
    font-weight: 700;
  }

  * {
    letter-spacing: 0 !important;
  }
}

html[lang="mr-IN"] {
  body {
    font-family: $font-family_10;
  }

  button {
    font-family: $font-family_10;
  }

  input {
    font-family: $font-family_10;
  }

  select {
    font-family: $font-family_10;
  }

  textarea {
    font-family: $font-family_10;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
  }

  h4 {
    font-weight: 700;
  }

  h5 {
    font-weight: 700;
  }

  h6 {
    font-weight: 700;
  }

  * {
    letter-spacing: 0 !important;
  }
}

html[lang="el"] {
  body {
    font-family: $font-family_11;
  }

  button {
    font-family: $font-family_11;
  }

  input {
    font-family: $font-family_11;
  }

  select {
    font-family: $font-family_11;
  }

  textarea {
    font-family: $font-family_11;
  }

  h1 {
    font-weight: 700;
    line-height: 1.3;
  }

  h2 {
    font-weight: 700;
    line-height: 1.3;
  }

  h3 {
    font-weight: 700;
    line-height: 1.3;
  }

  h4 {
    font-weight: 700;
    line-height: 1.3;
  }

  h5 {
    font-weight: 700;
    line-height: 1.3;
  }

  h6 {
    font-weight: 700;
    line-height: 1.3;
  }

  * {
    letter-spacing: 0 !important;
  }
}

html[lang="gu-IN"] {
  body {
    font-family: $font-family_10;
  }

  button {
    font-family: $font-family_10;
  }

  input {
    font-family: $font-family_10;
  }

  select {
    font-family: $font-family_10;
  }

  textarea {
    font-family: $font-family_10;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
  }

  h4 {
    font-weight: 700;
  }

  h5 {
    font-weight: 700;
  }

  h6 {
    font-weight: 700;
  }

  * {
    letter-spacing: 0 !important;
  }
}

html[lang="he-IL"] {
  body {
    font-family: $font-family_12;
  }

  button {
    font-family: $font-family_12;
  }

  input {
    font-family: $font-family_12;
  }

  select {
    font-family: $font-family_12;
  }

  textarea {
    font-family: $font-family_12;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
  }

  h4 {
    font-weight: 700;
  }

  h5 {
    font-weight: 700;
  }

  h6 {
    font-weight: 700;
  }

  * {
    letter-spacing: 0 !important;
  }
}

html[lang="ja"] {
  body {
    font-family: $font-family_13;
  }

  button {
    font-family: $font-family_13;
  }

  input {
    font-family: $font-family_13;
  }

  select {
    font-family: $font-family_13;
  }

  textarea {
    font-family: $font-family_13;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
  }

  h4 {
    font-weight: 700;
  }

  h5 {
    font-weight: 700;
  }

  h6 {
    font-weight: 700;
  }

  * {
    letter-spacing: 0 !important;
  }
}

html[lang="ko-KR"] {
  body {
    font-family: $font-family_14;
  }

  button {
    font-family: $font-family_14;
  }

  input {
    font-family: $font-family_14;
  }

  select {
    font-family: $font-family_14;
  }

  textarea {
    font-family: $font-family_14;
  }

  h1 {
    font-weight: 600;
  }

  h2 {
    font-weight: 600;
  }

  h3 {
    font-weight: 600;
  }

  h4 {
    font-weight: 600;
  }

  h5 {
    font-weight: 600;
  }

  h6 {
    font-weight: 600;
  }

  * {
    letter-spacing: 0 !important;
  }
}

html[lang="th"] {
  h1 {
    line-height: 1.65;
    font-family: $font-family_15;
  }

  h2 {
    line-height: 1.65;
    font-family: $font-family_15;
  }

  h3 {
    line-height: 1.65;
    font-family: $font-family_15;
  }

  h4 {
    line-height: 1.65;
    font-family: $font-family_15;
  }

  h5 {
    line-height: 1.65;
    font-family: $font-family_15;
  }

  h6 {
    line-height: 1.65;
    font-family: $font-family_15;
  }

  body {
    line-height: 1.8;
    font-family: $font-family_15;
  }

  button {
    line-height: 1.8;
    font-family: $font-family_15;
  }

  input {
    line-height: 1.8;
    font-family: $font-family_15;
  }

  select {
    line-height: 1.8;
    font-family: $font-family_15;
  }

  textarea {
    line-height: 1.8;
    font-family: $font-family_15;
  }

  * {
    letter-spacing: 0 !important;
  }
}

label {
  color: $color_4;
  display: block;
  font-weight: 800;
  margin-bottom: 0.5em;
}

input[type="text"] {
  color: $color_5;
  background: #fff;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #bbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  padding: 0.7em;
  width: 100%;

  &:focus {
    color: $color_1;
    border-color: $border-color_1;
  }
}

input[type="email"] {
  color: $color_5;
  background: #fff;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #bbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  padding: 0.7em;
  width: 100%;

  &:focus {
    color: $color_1;
    border-color: $border-color_1;
  }
}

input[type="url"] {
  color: $color_5;
  background: #fff;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #bbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  padding: 0.7em;
  width: 100%;

  &:focus {
    color: $color_1;
    border-color: $border-color_1;
  }
}

input[type="password"] {
  color: $color_5;
  background: #fff;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #bbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  padding: 0.7em;
  width: 100%;

  &:focus {
    color: $color_1;
    border-color: $border-color_1;
  }
}

input[type="search"] {
  color: $color_5;
  background: #fff;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #bbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  padding: 0.7em;
  width: 100%;

  &:focus {
    color: $color_1;
    border-color: $border-color_1;
  }
}

input[type="number"] {
  color: $color_5;
  background: #fff;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #bbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  padding: 0.7em;
  width: 100%;

  &:focus {
    color: $color_1;
    border-color: $border-color_1;
  }
}

input[type="tel"] {
  color: $color_5;
  background: #fff;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #bbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  padding: 0.7em;
  width: 100%;

  &:focus {
    color: $color_1;
    border-color: $border-color_1;
  }
}

input[type="range"] {
  color: $color_5;
  background: #fff;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #bbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  padding: 0.7em;
  width: 100%;

  &:focus {
    color: $color_1;
    border-color: $border-color_1;
  }
}

input[type="date"] {
  color: $color_5;
  background: #fff;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #bbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  padding: 0.7em;
  width: 100%;

  &:focus {
    color: $color_1;
    border-color: $border-color_1;
  }
}

input[type="month"] {
  color: $color_5;
  background: #fff;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #bbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  padding: 0.7em;
  width: 100%;

  &:focus {
    color: $color_1;
    border-color: $border-color_1;
  }
}

input[type="week"] {
  color: $color_5;
  background: #fff;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #bbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  padding: 0.7em;
  width: 100%;

  &:focus {
    color: $color_1;
    border-color: $border-color_1;
  }
}

input[type="time"] {
  color: $color_5;
  background: #fff;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #bbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  padding: 0.7em;
  width: 100%;

  &:focus {
    color: $color_1;
    border-color: $border-color_1;
  }
}

input[type="datetime"] {
  color: $color_5;
  background: #fff;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #bbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  padding: 0.7em;
  width: 100%;

  &:focus {
    color: $color_1;
    border-color: $border-color_1;
  }
}

input[type="datetime-local"] {
  color: $color_5;
  background: #fff;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #bbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  padding: 0.7em;
  width: 100%;

  &:focus {
    color: $color_1;
    border-color: $border-color_1;
  }
}

input[type="color"] {
  color: $color_5;
  background: #fff;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #bbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  padding: 0.7em;
  width: 100%;

  &:focus {
    color: $color_1;
    border-color: $border-color_1;
  }
}

input[type="radio"] {
  &:focus {
    outline: thin dotted #333;
  }

  margin-right: 0.5em;

  + {
    label {
      font-weight: 400;
    }
  }
}

input[type="checkbox"] {
  &:focus {
    outline: thin dotted #333;
  }

  margin-right: 0.5em;

  + {
    label {
      font-weight: 400;
    }
  }
}

input[type="button"] {
  background-color: $background-color_4;
  border: 0;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $color_7;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1;
  padding: 1em 2em;
  text-shadow: none;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;

  &:hover {
    background: #767676;
  }

  &:focus {
    background: #767676;
  }
}

input[type="submit"] {
  background-color: $background-color_4;
  border: 0;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $color_7;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1;
  padding: 1em 2em;
  text-shadow: none;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;

  &:hover {
    background: #767676;
  }

  &:focus {
    background: #767676;
  }
}

button.secondary {
  background-color: $background-color_5;
  color: $color_1;

  &:hover {
    background: #bbb;
  }

  &:focus {
    background: #bbb;
  }
}

input[type="reset"] {
  background-color: $background-color_5;
  color: $color_1;

  &:hover {
    background: #bbb;
  }

  &:focus {
    background: #bbb;
  }
}

input[type="button"].secondary {
  background-color: $background-color_5;
  color: $color_1;

  &:hover {
    background: #bbb;
  }

  &:focus {
    background: #bbb;
  }
}

input[type="reset"].secondary {
  background-color: $background-color_5;
  color: $color_1;

  &:hover {
    background: #bbb;
  }

  &:focus {
    background: #bbb;
  }
}

input[type="submit"].secondary {
  background-color: $background-color_5;
  color: $color_1;

  &:hover {
    background: #bbb;
  }

  &:focus {
    background: #bbb;
  }
}

::-webkit-input-placeholder {
  color: $color_4;
  font-family: $font-family_3;
}

:-moz-placeholder {
  color: $color_4;
  font-family: $font-family_3;
}

::-moz-placeholder {
  color: $color_4;
  font-family: $font-family_3;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $color_4;
  font-family: $font-family_3;
}

ul {
  margin: 0 0 1.5em;
  padding: 0;
  list-style: disc;
}

ol {
  margin: 0 0 1.5em;
  padding: 0;
  list-style: decimal;
}

li {
  > ul {
    margin-bottom: 0;
    margin-left: 1.5em;
  }

  > ol {
    margin-bottom: 0;
    margin-left: 1.5em;
  }
}

dt {
  font-weight: 700;
}

dd {
  margin: 0 1.5em 1.5em;
}

table {
  border-collapse: collapse;
  margin: 0 0 1.5em;
  width: 100%;
}

thead {
  th {
    border-bottom: 2px solid #bbb;
    padding-bottom: 0.5em;
  }
}

th {
  padding: 0.4em;
  text-align: left;
  padding-left: 0;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

tr {
  border-bottom: 1px solid #eee;
}

td {
  padding: 0.4em;
  padding-left: 0;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.entry-summary {
  a {
    -webkit-box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
    box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;

    &:focus {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }

    &:hover {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }
  }
}

.posts-navigation {
  a {
    -webkit-box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
    box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;

    &:focus {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }

    &:hover {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }
  }
}

.widget_authors {
  a {
    strong {
      -webkit-box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
      box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
      -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
    }

    &:focus {
      strong {
        color: $color_8;
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      }
    }

    &:hover {
      strong {
        color: $color_8;
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      }
    }
  }
}

.entry-title {
  a {
    -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    text-decoration: none;
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
    color: $color_4;
    text-decoration: none;
    margin-left: -2px;

    &:focus {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }

    &:hover {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }
  }

  &:not(:first-child) {
    padding-top: 0;
  }
}

.entry-meta {
  a {
    -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    text-decoration: none;
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
    color: $color_6;

    &:focus {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }

    &:hover {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }
  }

  color: $color_6;
  font-size: 11px;
  font-size: 0.6875rem;
  font-weight: 800;
  letter-spacing: 0.1818em;
  padding-bottom: 0.25em;
  text-transform: uppercase;
}

.page-links {
  a {
    -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    text-decoration: none;
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
    display: inline-block;

    .page-number {
      -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
      box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
      text-decoration: none;
      -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
      transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
      color: $color_1;
    }

    &:focus {
      .page-number {
        color: $color_8;
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      }
    }

    &:hover {
      .page-number {
        color: $color_8;
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      }
    }
  }

  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 800;
  padding: 2em 0 3em;
  clear: both;
  margin: 0 0 1.5em;

  .page-number {
    color: $color_6;
    display: inline-block;
    padding: 0.5em 1em;
  }
}

.edit-link {
  a {
    -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    text-decoration: none;
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;

    &:focus {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }

    &:hover {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }
  }
}

.post-navigation {
  a {
    -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    text-decoration: none;
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;

    &:focus {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);

      .icon {
        color: $color_1;
      }
    }

    &:hover {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);

      .icon {
        color: $color_1;
      }
    }
  }

  font-weight: 800;
  margin: 3em 0;

  .nav-links {
    padding: 1em 0;
  }

  .nav-next {
    margin-top: 1.5em;
  }
}

.logged-in-as {
  a {
    -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    text-decoration: none;
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;

    &:focus {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }

    &:hover {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }
  }
}

.comment-navigation {
  a {
    -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    text-decoration: none;
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
  }
}

.comment-metadata {
  a {
    -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    text-decoration: none;
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
    color: $color_6;

    &:focus {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }

    &:hover {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }
  }

  a.comment-edit-link {
    -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    text-decoration: none;
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
    color: $color_1;
    margin-left: 1em;

    &:focus {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }

    &:hover {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }
  }

  color: $color_6;
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 800;
  letter-spacing: 0.1818em;
  text-transform: uppercase;
}

.comment-reply-link {
  -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
  text-decoration: none;
  -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
  transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
  transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
  transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
  font-weight: 800;
  position: relative;

  &:focus {
    color: $color_8;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
  }

  &:hover {
    color: $color_8;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
  }

  .icon {
    color: $color_1;
    left: -2em;
    height: 1em;
    position: absolute;
    top: 0;
    width: 1em;
  }
}

.comments-pagination {
  a {
    -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
    text-decoration: none;
    -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;

    &:not(.prev) {
      &:not(.next) {
        &:focus {
          color: $color_8;
          -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
          box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        }

        &:hover {
          color: $color_8;
          -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
          box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
        }
      }
    }
  }

  border-top: 1px solid #eee;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 800;
  padding: 2em 0 3em;
  text-align: center;
  border: 0;
  margin: 2em 0 3em;

  .icon {
    width: 0.666666666em;
    height: 0.666666666em;
  }
}

.site-info {
  font-size: 14px;
  font-size: 0.875rem;
  margin-bottom: 1em;

  a {
    text-decoration: none;
    color: $color_5;
    box-shadow: inset 0 -1px 0 rgb(15, 15, 15);
    transition: color 80ms ease-in, box-shadow 130ms ease-in-out;

    &:focus {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }

    &:hover {
      color: $color_8;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 3px 0 rgba(0, 0, 0, 1);
    }
  }

  .sep {
    margin: 0;
    display: block;
    visibility: hidden;
    height: 0;
    width: 100%;
  }

  span[role=separator] {
    padding: 0 0.2em 0 0.4em;

    &::before {
      content: '\002f';
    }
  }
}

.post-thumbnail {
  margin-bottom: 1em;

  a {
    img {
      -webkit-backface-visibility: hidden;
      -webkit-transition: opacity 0.2s;
      transition: opacity 0.2s;
    }

    &:hover {
      img {
        opacity: 0.7;
      }
    }

    &:focus {
      img {
        opacity: 0.7;
      }
    }
  }
}

.navigation-top {
  background: #fff;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  font-size: 16px;
  font-size: 1rem;
  position: relative;

  .wrap {
    max-width: 1000px;
    padding: 0;
  }

  a {
    color: $color_1;
    font-weight: 600;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
  }

  .current-menu-item {
    > a {
      color: $color_6;
    }
  }

  .current_page_item {
    > a {
      color: $color_6;
    }
  }
}

.main-navigation {
  clear: both;
  display: block;
  position: relative;

  ul {
    background: #fff;
    list-style: none;
    margin: 0;
    padding: 0 1.5em;
    text-align: left;

    a {
      > .icon {
        display: none;
      }
    }

    ul {
      padding: 0 0 0 1.5em;

      a {
        letter-spacing: 0;
        padding: 0.4em 0;
        position: relative;
        text-transform: none;
      }
    }

    ul.toggled-on {
      display: block;
    }
  }

  .menu-item-has-children {
    > a {
      > .icon {
        display: none;
      }
    }
  }

  .page_item_has_children {
    > a {
      > .icon {
        display: none;
      }
    }
  }

  > div {
    > ul {
      border-top: 1px solid #eee;
      padding: 0.75em 1.695em;
    }
  }

  li {
    border-bottom: 1px solid #eee;
    position: relative;

    li {
      border: 0;
    }

    &:last-child {
      border: 0;
    }
  }

  a {
    display: block;
    padding: 0.5em 0;
    text-decoration: none;

    &:hover {
      color: $color_6;
    }
  }
}

.js {
  .main-navigation {
    ul {
      display: none;
    }
  }

  .main-navigation.toggled-on {
    > div {
      > ul {
        display: block;
      }
    }
  }

  .menu-toggle {
    display: block;
  }
}

.menu-toggle {
  background-color: $background-color_1;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $color_1;
  display: none;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1.5;
  margin: 1px auto 2px;
  padding: 1em;
  text-shadow: none;

  &:hover {
    background-color: $background-color_1;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &:focus {
    background-color: $background-color_1;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: thin solid;
  }

  .icon {
    margin-right: 0.5em;
    top: -2px;
  }

  .icon-close {
    display: none;
  }
}

.main-navigation.toggled-on {
  ul.nav-menu {
    display: block;
  }
}

.toggled-on {
  .menu-toggle {
    .icon-bars {
      display: none;
    }

    .icon-close {
      display: inline-block;
    }
  }
}

.dropdown-toggle {
  background-color: $background-color_1;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $color_1;
  display: block;
  font-size: 16px;
  right: -0.5em;
  line-height: 1.5;
  margin: 0 auto;
  padding: 0.5em;
  position: absolute;
  text-shadow: none;
  top: 0;

  &:hover {
    background: transparent;
  }

  &:focus {
    background: transparent;
    outline: thin dotted;
  }
}

.dropdown-toggle.toggled-on {
  .icon {
    -ms-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}

* {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;

  &:before {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }

  &:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }
}

#page {
  position: relative;
  word-wrap: break-word;
}

.wrap {
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  padding-left: 2em;
  padding-right: 2em;

  &:after {
    clear: both;
    content: "";
    display: block;
  }
}

#masthead {
  .wrap {
    position: relative;
  }
}

.site-branding {
  padding: 1em 0;
  position: relative;
  -webkit-transition: margin-bottom 0.2s;
  transition: margin-bottom 0.2s;
  z-index: 3;

  a {
    text-decoration: none;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }

    &:focus {
      opacity: 0.7;
    }
  }
}

.site-title {
  clear: none;
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: 0.08em;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  color: $color_1;
  opacity: 1;

  a {
    color: $color_1;
    opacity: 1;
  }
}

body.has-header-image {
  .site-title {
    color: $color_7;

    a {
      color: $color_7;
    }
  }

  .site-description {
    color: $color_7;
    opacity: 0.8;
  }
}

.site-description {
  color: $color_5;
  font-size: 13px;
  font-size: 0.8125rem;
  margin-bottom: 0;
}

.custom-logo-link {
  display: inline-block;
  padding-right: 1em;
  vertical-align: middle;
  width: auto;

  img {
    display: inline-block;
    max-height: 80px;
    width: auto;
  }

  a {
    &:hover {
      opacity: 0.9;
    }

    &:focus {
      opacity: 0.9;
    }
  }
}

body.title-tagline-hidden.has-header-image {
  .custom-logo-link {
    img {
      max-height: 200px;
      max-width: 100%;
    }
  }
}

.custom-header {
  position: relative;
}

.has-header-image .full-header-page {
  .custom-header {
    display: table;
    height: 300px;
    height: 75vh;
    width: 100%;
  }

  .site-branding {
    display: table-cell;
    height: 100%;
    vertical-align: bottom;
  }
}

.custom-header-media {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  &:before {
    background: -moz-linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 75%, rgba(0, 0, 0, 0.3) 100%);
    background: -webkit-linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 75%, rgba(0, 0, 0, 0.3) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 75%, rgba(0, 0, 0, 0.3) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#4d000000", GradientType=0);
    bottom: 0;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 2;
  }
}

.has-header-image {
  .custom-header-media {
    img {
      position: fixed;
      height: auto;
      left: 50%;
      max-width: 1000%;
      min-height: 100%;
      min-width: 100%;
      min-width: 100vw;
      width: auto;
      top: 50%;
      padding-bottom: 1px;
      -ms-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .small-header-page {
    .custom-header-media {
      img {
        bottom: 0;
        position: absolute;
        top: auto;
        -ms-transform: translateX(-50%) translateY(0);
        -moz-transform: translateX(-50%) translateY(0);
        -webkit-transform: translateX(-50%) translateY(0);
        transform: translateX(-50%) translateY(0);
      }
    }
  }
}

.wp-custom-header {
  .wp-custom-header-video-button {
    background-color: $background-color_8;
    border: 1px solid rgba(255, 255, 255, 0.6);
    color: $color_9;
    height: 45px;
    overflow: hidden;
    padding: 0;
    position: fixed;
    right: 30px;
    top: 30px;
    -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
    width: 45px;

    &:hover {
      border-color: $border-color_2;
      background-color: $background-color_9;
      color: $color_7;
    }

    &:focus {
      border-color: $border-color_2;
      background-color: $background-color_9;
      color: $color_7;
    }
  }
}

@supports ( object-fit: cover ) {
  .has-header-image .custom-header-media img,
  .has-header-image .small-header-page .custom-header-media img {
    height: 100%;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    top: 0;
    -ms-transform: none;
    -moz-transform: none;
    -webkit-transform: none;
    transform: none;
    width: 100%;
  }
}

body {
  &:not(.has-header-image) {
    .custom-header-media {
      display: none;
    }
  }
}

.full-header-page {
  article {
    &:not(.has-post-thumbnail) {
      &:not(:first-child) {
        border-top: 1px solid #ddd;
      }
    }
  }

  .panel-content {
    .recent-posts {
      article {
        border: 0;
        color: $color_4;
        margin-bottom: 3em;
      }
    }
  }
}

.twentyseventeen-panel {
  overflow: hidden;
  position: relative;

  .edit-link {
    display: block;
    margin: 0.3em 0 0;
  }

  .entry-header {
    .edit-link {
      font-size: 14px;
      font-size: 0.875rem;
    }
  }

  .recent-posts {
    .entry-header {
      .edit-link {
        color: $color_1;
        display: inline-block;
        font-size: 11px;
        font-size: 0.6875rem;
        margin-left: 1em;
      }
    }
  }

  > .customize-partial-edit-shortcut {
    > button {
      top: 30px;
      left: 30px;
    }
  }

  .customize-partial-edit-shortcut-hidden {
    &:before {
      visibility: visible;
    }
  }
}

.panel-image {
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;

  &:before {
    background: -webkit-linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.3)));
    background: -webkit-linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#4d000000", GradientType=0);
    bottom: 0;
    content: "";
    left: 0;
    right: 0;
    position: absolute;
    top: 100px;
  }
}

.panel-content {
  position: relative;

  .wrap {
    padding-bottom: 0.5em;
    padding-top: 1.75em;
  }
}

.recent-posts {
  .entry-header {
    margin-bottom: 1.2em;
  }
}

.page {
  .panel-content {
    .recent-posts {
      .entry-title {
        font-size: 20px;
        font-size: 1.25rem;
        font-weight: 300;
        letter-spacing: 0;
        text-transform: none;
      }
    }

    .entry-title {
      color: $color_1;
      font-size: 14px;
      font-size: 0.875rem;
      font-weight: 800;
      letter-spacing: 0.14em;
      text-transform: uppercase;
    }
  }

  .entry-header {
    .edit-link {
      font-size: 14px;
      font-size: 0.875rem;
    }
  }
}

.site-content-contain {
  background-color: $background-color_10;
  position: relative;
}

.sticky {
  position: relative;

  .icon-thumb-tack {
    display: block;
    height: 18px;
    left: -1.5em;
    position: absolute;
    top: 1.65em;
    width: 20px;
  }
}

.post {
  &:not(.sticky) {
    .icon-thumb-tack {
      display: none;
    }
  }
}

.page-title {
  color: $color_1;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 800;
  letter-spacing: 0.14em;
  text-transform: uppercase;
}

body.page {
  .entry-title {
    color: $color_1;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 800;
    letter-spacing: 0.14em;
    text-transform: uppercase;
  }
}

.entry-header {
  .entry-title {
    margin-bottom: 0.25em;
  }
}

.byline {
  display: none;
}

.updated {
  &:not(.published) {
    display: none;
  }
}

.single {
  .byline {
    display: inline;
  }
}

.group-blog {
  .byline {
    display: inline;
  }
}

.page-numbers {
  display: none;
  padding: 0.5em 0.75em;
}

.page-numbers.current {
  color: $color_6;
  display: inline-block;

  .screen-reader-text {
    clip: auto;
    height: auto;
    overflow: auto;
    position: relative !important;
    width: auto;
  }
}

.prev.page-numbers {
  background-color: $background-color_5;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  display: inline-block;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1;
  padding: 0.25em 0.5em 0.4em;
  -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
  float: left;

  &:focus {
    background-color: $background-color_11;
    color: $color_7;
  }

  &:hover {
    background-color: $background-color_11;
    color: $color_7;
  }
}

.next.page-numbers {
  background-color: $background-color_5;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  display: inline-block;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1;
  padding: 0.25em 0.5em 0.4em;
  -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
  float: right;

  &:focus {
    background-color: $background-color_11;
    color: $color_7;
  }

  &:hover {
    background-color: $background-color_11;
    color: $color_7;
  }
}

.blog {
  .site-main {
    > article {
      padding-bottom: 2em;
    }
  }

  .entry-meta {
    a.post-edit-link {
      color: $color_1;
      display: inline-block;
      margin-left: 1em;
      white-space: nowrap;
    }
  }

  .format-status {
    .entry-title {
      display: none;
    }
  }

  .format-aside {
    .entry-title {
      display: none;
    }
  }
}

.archive {
  .site-main {
    > article {
      padding-bottom: 2em;
    }
  }

  .entry-meta {
    a.post-edit-link {
      color: $color_1;
      display: inline-block;
      margin-left: 1em;
      white-space: nowrap;
    }
  }

  .format-status {
    .entry-title {
      display: none;
    }
  }

  .format-aside {
    .entry-title {
      display: none;
    }
  }
}

.search {
  .site-main {
    > article {
      padding-bottom: 2em;
    }
  }

  .entry-meta {
    a.post-edit-link {
      color: $color_1;
      display: inline-block;
      margin-left: 1em;
      white-space: nowrap;
    }
  }

  .page {
    .entry-meta {
      a.post-edit-link {
        margin-left: 0;
        white-space: nowrap;
      }
    }

    .entry-header {
      .edit-link {
        font-size: 11px;
        font-size: 0.6875rem;
      }
    }
  }

  .page-content {
    .search-form {
      margin-bottom: 3em;
    }
  }
}

.taxonomy-description {
  color: $color_5;
  font-size: 13px;
  font-size: 0.8125rem;
}

.single-post {
  &:not(.has-sidebar) {
    #primary {
      margin-left: auto;
      margin-right: auto;
      max-width: 740px;
    }
  }
}

.page.page-one-column {
  .small-header-page {
    #primary {
      margin-left: auto;
      margin-right: auto;
      max-width: 740px;
    }
  }
}

.archive.page-one-column {
  &:not(.has-sidebar) {
    .page-header {
      margin-left: auto;
      margin-right: auto;
      max-width: 740px;
    }

    #primary {
      margin-left: auto;
      margin-right: auto;
      max-width: 740px;
    }
  }
}

.single-featured-image-header {
  background-color: $background-color_7;
  border-bottom: 1px solid #eee;

  img {
    display: block;
    margin: auto;
  }
}

.format-quote {
  blockquote {
    color: $color_4;
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 300;
    overflow: visible;
    position: relative;

    .icon {
      display: block;
      height: 20px;
      left: -1.25em;
      position: absolute;
      top: 0.4em;
      -webkit-transform: scale(-1, 1);
      -ms-transform: scale(-1, 1);
      transform: scale(-1, 1);
      width: 20px;
    }
  }
}

.nav-subtitle {
  background: transparent;
  color: $color_6;
  display: block;
  font-size: 11px;
  font-size: 0.6875rem;
  letter-spacing: 0.1818em;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.nav-title {
  color: $color_4;
  font-size: 15px;
  font-size: 0.9375rem;
}

.page-header {
  padding-bottom: 2em;
}

.error404 {
  .page-content {
    padding-bottom: 4em;

    .search-form {
      margin-bottom: 3em;
    }
  }
}

.social-navigation {
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 1em;

  ul {
    list-style: none;
    margin-bottom: 0;
    margin-left: 0;
  }

  li {
    display: inline;
  }

  a {
    background-color: $background-color_11;
    -webkit-border-radius: 40px;
    border-radius: 40px;
    color: $color_7;
    display: inline-block;
    height: 40px;
    margin: 0 1em 0.5em 0;
    text-align: center;
    width: 40px;

    &:hover {
      background-color: $background-color_12;
    }

    &:focus {
      background-color: $background-color_12;
    }
  }

  .icon {
    height: 16px;
    top: 12px;
    width: 16px;
    vertical-align: top;
  }
}

#comments {
  clear: both;
  padding: 2em 0 0.5em;
}

.comments-title {
  font-size: 20px;
  font-size: 1.25rem;
  margin-bottom: 1.5em;
}

.comment-list {
  list-style: none;
  margin: 0;
  padding: 0;

  .children {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    &:before {
      display: none;
    }
  }
}

.comment-body {
  margin-left: 65px;
  color: $color_4;
  font-size: 14px;
  font-size: 0.875rem;
  margin-bottom: 4em;
}

.children {
  .comment-author {
    .avatar {
      height: 30px;
      left: -45px;
      width: 30px;
    }
  }
}

.bypostauthor {
  > .comment-body {
    > .comment-meta {
      > .comment-author {
        .avatar {
          border: 1px solid #333;
          padding: 2px;
        }
      }
    }
  }
}

.no-comments {
  color: $color_6;
  font-size: 14px;
  font-size: 0.875rem;
  font-style: italic;
}

.comment-awaiting-moderation {
  color: $color_6;
  font-size: 14px;
  font-size: 0.875rem;
  font-style: italic;
}

.form-submit {
  text-align: right;
}

.comment-form {
  #wp-comment-cookies-consent {
    margin: 0 10px 0 0;
  }

  .comment-form-cookies-consent {
    label {
      display: inline;
    }
  }
}

#secondary {
  padding: 1em 0 2em;
}

h2.widget-title {
  color: $color_1;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 800;
  letter-spacing: 0.1818em;
  margin-bottom: 1.5em;
  text-transform: uppercase;
}

.widget-title {
  a {
    color: $color_2;
  }
}

.widget_top-posts {
  ul {
    li {
      ul {
        bottom: 0;
      }

      padding-bottom: 0.25em;
      padding-top: 0.25em;

      li {
        padding-bottom: 0.25em;
        padding-top: 0.25em;
      }
    }
  }
}

.widget_rss_links {
  ul {
    li {
      ul {
        bottom: 0;
      }

      padding-bottom: 0.25em;
      padding-top: 0.25em;

      li {
        padding-bottom: 0.25em;
        padding-top: 0.25em;
      }
    }
  }
}

.widget-grofile {
  ul.grofile-links {
    li {
      ul {
        bottom: 0;
      }

      padding-bottom: 0.25em;
      padding-top: 0.25em;

      li {
        padding-bottom: 0.25em;
        padding-top: 0.25em;
      }
    }
  }

  h4 {
    font-size: 16px;
    font-size: 1rem;
    margin-bottom: 0;
  }
}

.widget_pages {
  ul {
    li {
      ul {
        bottom: 0;
      }
    }
  }
}

.widget_meta {
  ul {
    li {
      ul {
        bottom: 0;
      }
    }
  }
}

.widget_nav_menu {
  ul {
    li {
      li {
        padding-bottom: 0.25em;
        padding-top: 0.25em;
      }
    }
  }
}

.widget_rss {
  ul {
    li {
      padding-bottom: 1em;
      padding-top: 1em;
      padding: 2.125em 0;

      &:first-child {
        border-top: none;
        padding-top: 0;
      }
    }
  }

  .widget-title {
    .rsswidget {
      &:first-child {
        float: right;

        &:hover {
          background-color: $background-color_1;
        }

        img {
          display: block;
        }
      }
    }
  }

  li {
    .rsswidget {
      font-size: 22px;
      font-size: 1.375rem;
      font-weight: 300;
      line-height: 1.4;
    }

    cite {
      color: $color_6;
      display: block;
      font-size: 10px;
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 800;
      letter-spacing: 0.18em;
      line-height: 1.5;
      text-transform: uppercase;
    }
  }

  .rss-date {
    color: $color_6;
    display: block;
    font-size: 10px;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0.18em;
    line-height: 1.5;
    text-transform: uppercase;
    margin: 0.5em 0 1.5em;
    padding: 0;
  }

  .rssSummary {
    margin-bottom: 0.5em;
  }
}

.widget_text {
  word-wrap: break-word;

  ul {
    list-style: disc;
    margin: 0 0 1.5em 1.5em;

    li {
      border: none;

      &:last-child {
        padding-bottom: 0;
      }

      ul {
        margin: 0 0 0 1.5em;
      }

      li {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  ol {
    list-style: decimal;

    li {
      border: none;
      list-style-position: inside;

      &:last-child {
        padding-bottom: 0;
      }

      + {
        li {
          margin-top: -1px;
        }
      }
    }
  }
}

.widget_contact_info {
  .contact-map {
    margin-bottom: 0.5em;
  }
}

.widget_recent_comments {
  table {
    border: 0;
  }

  th {
    border: 0;
  }

  td {
    border: 0;
  }
}

.widget_recent_entries {
  .post-date {
    display: block;
  }
}

.search-form {
  position: relative;

  .search-submit {
    bottom: 3px;
    padding: 0.5em 1em;
    position: absolute;
    right: 3px;
    top: 3px;

    .icon {
      height: 24px;
      top: -2px;
      width: 24px;
    }
  }
}

.tagcloud {
  ul {
    li {
      float: left;
      border-top: 0;
      border-bottom: 0;
      padding: 0;
      margin: 4px 4px 0 0;
    }
  }

  line-height: 1.5;
}

.widget_tag_cloud {
  line-height: 1.5;
}

.wp_widget_tag_cloud {
  line-height: 1.5;

  a {
    border: 1px solid #ddd;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: block;
    padding: 4px 10px 5px;
    position: relative;
    -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
    width: auto;
    word-wrap: break-word;
    z-index: 0;

    &:hover {
      border-color: $border-color_3;
      -webkit-box-shadow: none;
      box-shadow: none;
      text-decoration: none;
    }

    &:focus {
      border-color: $border-color_3;
      -webkit-box-shadow: none;
      box-shadow: none;
      text-decoration: none;
    }
  }
}

.widget.widget_tag_cloud {
  a {
    border: 1px solid #ddd;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: block;
    padding: 4px 10px 5px;
    position: relative;
    -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
    width: auto;
    word-wrap: break-word;
    z-index: 0;

    &:hover {
      border-color: $border-color_3;
      -webkit-box-shadow: none;
      box-shadow: none;
      text-decoration: none;
    }

    &:focus {
      border-color: $border-color_3;
      -webkit-box-shadow: none;
      box-shadow: none;
      text-decoration: none;
    }
  }
}

.widget_calendar {
  .wp-calendar-table {
    margin-bottom: 0;
  }

  .wp-calendar-nav {
    display: table;
    width: 100%;
    margin: 0 0 1.5em;
    padding: 0.4em 0;

    span {
      display: table-cell;
      text-align: center;
    }
  }

  .wp-calendar-nav-prev {
    width: 40%;
  }

  .wp-calendar-nav-next {
    width: 40%;
  }

  th {
    text-align: center;
  }

  td {
    text-align: center;
  }

  tfoot {
    td {
      border: 0;
    }
  }
}

.gallery-columns-5 {
  .gallery-caption {
    display: none;
  }
}

.gallery-columns-6 {
  .gallery-caption {
    display: none;
  }
}

.gallery-columns-7 {
  .gallery-caption {
    display: none;
  }
}

.gallery-columns-8 {
  .gallery-caption {
    display: none;
  }
}

.gallery-columns-9 {
  .gallery-caption {
    display: none;
  }
}

img.alignleft {
  float: none;
  margin: 0;
}

img.alignright {
  float: none;
  margin: 0;
}

.page-content {
  .wp-smiley {
    border: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
  }
}

embed {
  margin-bottom: 1.5em;
  max-width: 100%;
}

iframe {
  margin-bottom: 1.5em;
  max-width: 100%;
}

object {
  margin-bottom: 1.5em;
  max-width: 100%;
}

.wp-caption {
  color: $color_5;
  font-size: 13px;
  font-size: 0.8125rem;
  font-style: italic;
  margin-bottom: 1.5em;
  max-width: 100%;

  img[class*="wp-image-"] {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .wp-caption-text {
    margin: 0.8075em 0;
  }
}

.gallery-caption {
  color: $color_5;
  font-size: 13px;
  font-size: 0.8125rem;
  font-style: italic;
  margin-bottom: 1.5em;
  max-width: 100%;
  display: block;
  text-align: left;
  padding: 0 10px 0 0;
  margin-bottom: 0;
}

.mejs-container {
  margin-bottom: 1.5em;
}

.mejs-controls {
  a.mejs-horizontal-volume-slider {
    background: transparent;
    border: 0;

    &:focus {
      background: transparent;
      border: 0;
    }

    &:hover {
      background: transparent;
      border: 0;
    }
  }
}

.icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  position: relative;
  top: -0.0625em;
  vertical-align: middle;
  width: 1em;
}

.gallery-item {
  display: inline-block;
  text-align: left;
  vertical-align: top;
  margin: 0 0 1.5em;
  padding: 0 1em 0 0;
  width: 50%;

  a {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: none;
    display: inline-block;
    max-width: 100%;

    &:hover {
      -webkit-box-shadow: none;
      box-shadow: none;
      background: none;
      display: inline-block;
      max-width: 100%;

      img {
        -webkit-filter: opacity(60%);
        filter: opacity(60%);
      }
    }

    &:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
      background: none;
      display: inline-block;
      max-width: 100%;

      img {
        -webkit-filter: opacity(60%);
        filter: opacity(60%);
      }
    }

    img {
      display: block;
      -webkit-transition: -webkit-filter 0.2s ease-in;
      transition: -webkit-filter 0.2s ease-in;
      transition: filter 0.2s ease-in;
      transition: filter 0.2s ease-in, -webkit-filter 0.2s ease-in;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
  }
}

.gallery-columns-1 {
  .gallery-item {
    width: 100%;
  }
}

.gallery-columns-2 {
  .gallery-item {
    max-width: 50%;
  }
}

.svg-fallback {
  display: none;
}

.no-svg {
  .svg-fallback {
    display: inline-block;
  }

  .dropdown-toggle {
    padding: 0.5em 0 0;
    right: 0;
    text-align: center;
    width: 2em;

    .svg-fallback.icon-angle-down {
      font-size: 20px;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);

      &:before {
        content: "\005E";
      }
    }
  }

  .dropdown-toggle.toggled-on {
    .svg-fallback.icon-angle-down {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
    }
  }

  .social-navigation {
    a {
      background: transparent;
      color: $color_1;
      height: auto;
      width: auto;
    }

    li {
      a {
        .screen-reader-text {
          clip: auto;
          font-size: 16px;
          font-size: 1rem;
          font-weight: 400;
          height: auto;
          position: relative !important;
          width: auto;
        }
      }
    }
  }

  .next.page-numbers {
    .screen-reader-text {
      clip: auto;
      font-size: 16px;
      font-size: 1rem;
      font-weight: 400;
      height: auto;
      position: relative !important;
      width: auto;
    }
  }

  .prev.page-numbers {
    .screen-reader-text {
      clip: auto;
      font-size: 16px;
      font-size: 1rem;
      font-weight: 400;
      height: auto;
      position: relative !important;
      width: auto;
    }
  }

  .search-submit {
    .screen-reader-text {
      clip: auto;
      font-size: 16px;
      font-size: 1rem;
      font-weight: 400;
      height: auto;
      position: relative !important;
      width: auto;
    }
  }
}

@media screen and (min-width: 20em) {
  body.customize-partial-edit-shortcuts-shown {
    .site-header {
      .site-title {
        padding-left: 0;
      }
    }
  }
}

@media screen and (min-width: 30em) {
  body {
    font-size: 18px;
    font-size: 1.125rem;
  }
  button {
    font-size: 18px;
    font-size: 1.125rem;
  }
  input {
    font-size: 18px;
    font-size: 1.125rem;
  }
  select {
    font-size: 18px;
    font-size: 1.125rem;
  }
  textarea {
    font-size: 18px;
    font-size: 1.125rem;
  }
  h1 {
    font-size: 30px;
    font-size: 1.875rem;
  }
  h2 {
    font-size: 26px;
    font-size: 1.625rem;
  }
  .page {
    .panel-content {
      .recent-posts {
        .entry-title {
          font-size: 26px;
          font-size: 1.625rem;
        }
      }
    }
  }
  h3 {
    font-size: 22px;
    font-size: 1.375rem;
  }
  h4 {
    font-size: 18px;
    font-size: 1.125rem;
  }
  h5 {
    font-size: 13px;
    font-size: 0.8125rem;
  }
  h6 {
    font-size: 16px;
    font-size: 1rem;
  }
  .entry-content {
    blockquote.alignleft {
      font-size: 14px;
      font-size: 0.875rem;
    }

    blockquote.alignright {
      font-size: 14px;
      font-size: 0.875rem;
    }
  }
  img.alignleft {
    float: left;
    margin-right: 1.5em;
  }
  img.alignright {
    float: right;
    margin-left: 1.5em;
  }
  .site-branding {
    padding: 3em 0;
  }
  .panel-content {
    .wrap {
      padding-bottom: 2em;
      padding-top: 3.5em;
    }

    .entry-header {
      margin-bottom: 4.5em;
    }

    .recent-posts {
      .entry-header {
        margin-bottom: 0;
      }
    }
  }
  .page-one-column {
    .panel-content {
      .wrap {
        max-width: 740px;
      }
    }
  }
  .taxonomy-description {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .page-numbers.current {
    font-size: 16px;
    font-size: 1rem;
  }
  .site-footer {
    font-size: 16px;
    font-size: 1rem;
  }
  .gallery-item {
    max-width: 25%;
  }
  .gallery-columns-1 {
    .gallery-item {
      max-width: 100%;
    }
  }
  .gallery-columns-2 {
    .gallery-item {
      max-width: 50%;
    }
  }
  .gallery-columns-3 {
    .gallery-item {
      max-width: 33.33%;
    }
  }
  .gallery-columns-4 {
    .gallery-item {
      max-width: 25%;
    }
  }
}

@media screen and (min-width: 48em) {
  body {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;

    &:not(.has-sidebar) {
      &:not(.page-one-column) {
        .page-header {
          float: left;
          width: 36%;
        }
      }
    }
  }
  button {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
  }
  input {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
  }
  select {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
  }
  textarea {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
  }
  .entry-content {
    blockquote.alignleft {
      font-size: 13px;
      font-size: 0.8125rem;
    }

    blockquote.alignright {
      font-size: 13px;
      font-size: 0.8125rem;
    }
  }
  .wrap {
    max-width: 1000px;
    padding-left: 3em;
    padding-right: 3em;
  }
  .has-sidebar {
    &:not(.error404) {
      #primary {
        float: left;
        width: 58%;
      }
    }

    #secondary {
      float: right;
      padding-top: 0;
      width: 36%;
    }

    .navigation.pagination {
      float: none;
      width: 100%;
    }

    .entry-content {
      blockquote.alignleft {
        margin-left: 0;
        width: 34%;
      }

      blockquote.alignright {
        margin-right: 0;
        width: 34%;
      }
    }

    #primary {
      .entry-content {
        blockquote.alignright.below-entry-meta {
          margin-right: -72.5%;
          width: 62%;
        }
      }
    }
  }
  .error404 {
    #primary {
      float: none;
    }

    &:not(.has-sidebar) {
      #primary {
        .page-content {
          float: right;
          width: 58%;
        }
      }
    }

    .page-content {
      padding-bottom: 9em;
    }
  }
  .site-branding {
    margin-bottom: 0;
  }
  .has-header-image .full-header-page {
    .site-branding {
      bottom: 0;
      display: block;
      left: 0;
      height: auto;
      padding-top: 0;
      position: absolute;
      width: 100%;
    }

    .custom-header {
      display: block;
      height: auto;
    }
  }
  .custom-header-media {
    height: 165px;
    position: relative;
  }
  .has-header-image .full-header-page {
    .custom-header-media {
      height: 0;
      position: relative;
      height: 1200px;
      height: 100vh;
      max-height: 100%;
      overflow: hidden;

      &:before {
        height: 33%;
      }
    }

    .site-branding {
      margin-bottom: 70px;
    }
  }
  .has-header-image {
    .small-header-page {
      .custom-header-media {
        bottom: 0;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  .custom-logo-link {
    padding-right: 2em;

    img {
      max-width: 350px;
    }
  }
  .site-title {
    font-size: 36px;
    font-size: 2.25rem;
  }
  .site-description {
    font-size: 16px;
    font-size: 1rem;
  }
  .navigation-top {
    bottom: 0;
    font-size: 14px;
    font-size: 0.875rem;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 3;

    .wrap {
      max-width: 1000px;
      padding: 0.75em 3.4166666666667em;
    }

    nav {
      margin-left: -1.25em;
    }
  }
  .site-navigation-fixed.navigation-top {
    bottom: auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
  .js {
    .menu-toggle {
      display: none;
    }

    .dropdown-toggle {
      display: none;
    }

    .main-navigation {
      ul {
        display: block;

        ul {
          display: block;
        }
      }

      > div {
        > ul {
          display: block;
        }
      }
    }
  }
  .main-navigation {
    width: auto;

    ul {
      background: transparent;
      padding: 0;

      ul {
        background: #fff;
        border: 1px solid #bbb;
        left: -999em;
        padding: 0;
        position: absolute;
        top: 100%;
        z-index: 99999;

        li {
          &:hover {
            > ul {
              left: 100%;
              right: auto;
            }
          }
        }

        li.focus {
          > ul {
            left: 100%;
            right: auto;
          }
        }

        a {
          padding: 0.75em 1.25em;
          width: 16em;
        }

        .menu-item-has-children {
          > a {
            > .icon {
              margin-top: -9px;
              left: auto;
              position: absolute;
              right: 1em;
              top: 50%;
              -webkit-transform: rotate(-90deg);
              -ms-transform: rotate(-90deg);
              transform: rotate(-90deg);
            }
          }
        }

        .page_item_has_children {
          > a {
            > .icon {
              margin-top: -9px;
              left: auto;
              position: absolute;
              right: 1em;
              top: 50%;
              -webkit-transform: rotate(-90deg);
              -ms-transform: rotate(-90deg);
              transform: rotate(-90deg);
            }
          }
        }

        ul {
          left: -999em;
          margin-top: -1px;
          top: 0;
        }

        li.menu-item-has-children.focus {
          &:before {
            display: none;
          }

          &:after {
            display: none;
          }
        }

        li.menu-item-has-children {
          &:hover {
            &:before {
              display: none;
            }

            &:after {
              display: none;
            }
          }
        }

        li.page_item_has_children.focus {
          &:before {
            display: none;
          }

          &:after {
            display: none;
          }
        }

        li.page_item_has_children {
          &:hover {
            &:before {
              display: none;
            }

            &:after {
              display: none;
            }
          }
        }
      }

      li.menu-item-has-children {
        &:before {
          border-style: solid;
          border-width: 0 6px 6px;
          content: "";
          display: none;
          height: 0;
          position: absolute;
          right: 1em;
          bottom: -1px;
          width: 0;
          z-index: 100000;
          border-color: $border-color_5;
          bottom: 0;
        }

        &:after {
          border-style: solid;
          border-width: 0 6px 6px;
          content: "";
          display: none;
          height: 0;
          position: absolute;
          right: 1em;
          bottom: -1px;
          width: 0;
          z-index: 100000;
          border-color: $border-color_6;
        }

        &:hover {
          &:before {
            display: block;
          }

          &:after {
            display: block;
          }
        }
      }

      li.page_item_has_children {
        &:before {
          border-style: solid;
          border-width: 0 6px 6px;
          content: "";
          display: none;
          height: 0;
          position: absolute;
          right: 1em;
          bottom: -1px;
          width: 0;
          z-index: 100000;
          border-color: $border-color_5;
          bottom: 0;
        }

        &:after {
          border-style: solid;
          border-width: 0 6px 6px;
          content: "";
          display: none;
          height: 0;
          position: absolute;
          right: 1em;
          bottom: -1px;
          width: 0;
          z-index: 100000;
          border-color: $border-color_6;
        }

        &:hover {
          &:before {
            display: block;
          }

          &:after {
            display: block;
          }
        }
      }

      li.menu-item-has-children.focus {
        &:before {
          display: block;
        }

        &:after {
          display: block;
        }
      }

      li.page_item_has_children.focus {
        &:before {
          display: block;
        }

        &:after {
          display: block;
        }
      }

      li {
        &:hover {
          > ul {
            left: 0.5em;
            right: auto;
          }
        }
      }

      li.focus {
        > ul {
          left: 0.5em;
          right: auto;
        }
      }
    }

    > div {
      > ul {
        border: 0;
        margin-bottom: 0;
        padding: 0;
      }
    }

    li {
      border: 0;
      display: inline-block;

      li {
        display: block;
        -webkit-transition: background-color 0.2s ease-in-out;
        transition: background-color 0.2s ease-in-out;

        &:hover {
          background: #767676;

          > a {
            color: $color_7;
          }
        }

        a {
          -webkit-transition: color 0.3s ease-in-out;
          transition: color 0.3s ease-in-out;

          &:hover {
            color: $color_7;
          }

          &:focus {
            color: $color_7;
          }
        }

        &:focus {
          > a {
            color: $color_7;
          }
        }
      }

      li.focus {
        background: #767676;

        > a {
          color: $color_7;
        }
      }

      li.current_page_item {
        a {
          &:hover {
            color: $color_7;
          }

          &:focus {
            color: $color_7;
          }
        }
      }

      li.current-menu-item {
        a {
          &:hover {
            color: $color_7;
          }

          &:focus {
            color: $color_7;
          }
        }
      }
    }

    a {
      padding: 1em 1.25em;
    }

    .menu-item-has-children {
      > a {
        > .icon {
          display: inline;
          left: 5px;
          position: relative;
          top: -1px;
        }
      }
    }

    .page_item_has_children {
      > a {
        > .icon {
          display: inline;
          left: 5px;
          position: relative;
          top: -1px;
        }
      }
    }
  }
  .site-header {
    .site-navigation-fixed {
      .menu-scroll-down {
        display: none;
      }
    }

    .menu-scroll-down {
      display: block;
      padding: 1em;
      position: absolute;
      right: 0;
      color: $color_7;
      top: 0;
    }

    .navigation-top {
      .menu-scroll-down {
        color: $color_6;
        top: 0;
      }
    }
  }
  .menu-scroll-down {
    &:focus {
      outline: thin dotted;
    }

    .icon {
      height: 18px;
      width: 18px;
    }
  }
  .panel-content {
    .wrap {
      padding-bottom: 4.5em;
      padding-top: 6em;
    }

    .recent-posts {
      article {
        margin-bottom: 4em;
      }

      .entry-header {
        float: none;
        width: 100%;
        margin-bottom: 1.5em;
      }

      .entry-content {
        float: none;
        width: 100%;
      }
    }
  }
  .panel-image {
    height: 100vh;
    max-height: 1200px;
  }
  .background-fixed {
    .panel-image {
      background-attachment: fixed;
    }
  }
  .page-two-column {
    .panel-content {
      .entry-header {
        float: left;
        width: 36%;
      }

      .entry-content {
        float: right;
        width: 58%;
      }

      .recent-posts {
        clear: right;
        float: right;
        width: 58%;
      }
    }

    #primary {
      .panel-content {
        .recent-posts {
          .entry-header {
            float: none;
            width: 100%;
          }

          .entry-content {
            float: none;
            width: 100%;
          }
        }
      }
    }

    .entry-content {
      blockquote.alignleft {
        margin-left: -72.5%;
        width: 62%;
      }

      blockquote.alignright {
        margin-right: 0;
        width: 36%;
      }
    }
  }
  .page {
    .panel-content {
      .recent-posts {
        .entry-title {
          font-size: 26px;
          font-size: 1.625rem;
        }
      }
    }

    .entry-title {
      font-size: 26px;
      font-size: 1.625rem;
    }
  }
  .site-content {
    padding: 5.5em 0 0;
  }
  .single-post {
    .entry-title {
      font-size: 26px;
      font-size: 1.625rem;
    }
  }
  .comments-pagination {
    clear: both;
  }
  .post-navigation {
    clear: both;

    .nav-previous {
      float: left;
      width: 50%;
    }

    .nav-next {
      float: right;
      text-align: right;
      width: 50%;
      margin-top: 0;
    }
  }
  .nav-next {
    margin-top: 0;
  }
  .sticky {
    .icon-thumb-tack {
      height: 23px;
      left: -2.5em;
      top: 1.5em;
      width: 32px;
    }
  }
  body.has-sidebar.error404 {
    #primary {
      .page-header {
        float: left;
        width: 36%;
      }
    }
  }
  body.page-two-column {
    &:not(.archive) {
      #primary {
        .entry-header {
          float: left;
          width: 36%;
        }

        .entry-content {
          float: right;
          width: 58%;
        }
      }
    }

    #comments {
      float: right;
      width: 58%;
    }
  }
  body.page-two-column.archive {
    &:not(.has-sidebar) {
      #primary {
        .page-header {
          float: left;
          width: 36%;
        }
      }
    }
  }
  .blog {
    &:not(.has-sidebar) {
      #primary {
        article {
          float: right;
          width: 58%;
        }
      }

      .entry-content {
        blockquote.alignleft {
          margin-left: -72.5%;
          width: 62%;
        }

        blockquote.alignright {
          margin-right: 0;
          width: 36%;
        }
      }
    }

    .site-main {
      > article {
        padding-bottom: 4em;
      }
    }
  }
  .archive {
    &:not(.page-one-column) {
      &:not(.has-sidebar) {
        #primary {
          article {
            float: right;
            width: 58%;
          }
        }
      }
    }

    .site-main {
      > article {
        padding-bottom: 4em;
      }
    }

    &:not(.has-sidebar) {
      .entry-content {
        blockquote.alignleft {
          margin-left: -72.5%;
          width: 62%;
        }

        blockquote.alignright {
          margin-right: 0;
          width: 36%;
        }
      }
    }
  }
  .search {
    &:not(.has-sidebar) {
      #primary {
        article {
          float: right;
          width: 58%;
        }
      }
    }

    .site-main {
      > article {
        padding-bottom: 4em;
      }
    }
  }
  .error404.has-sidebar {
    #primary {
      .page-content {
        float: right;
        width: 58%;
      }
    }
  }
  .navigation.pagination {
    clear: both;
    float: right;
    width: 58%;
  }
  .archive.page-one-column {
    &:not(.has-sidebar) {
      .navigation.pagination {
        float: none;
        width: 100%;
      }

      .page-header {
        margin-bottom: 4em;
      }
    }
  }
  .entry-footer {
    display: table;
    width: 100%;

    .cat-tags-links {
      display: table-cell;
      vertical-align: middle;
      width: 100%;
    }

    .edit-link {
      display: table-cell;
      text-align: right;
      vertical-align: middle;

      a.post-edit-link {
        margin-top: 0;
        margin-left: 1em;
      }
    }
  }

  :not(.has-sidebar) {
    .entry-content {
      blockquote.alignleft {
        margin-left: -17.5%;
        width: 48%;
      }

      blockquote.alignright {
        margin-right: -17.5%;
        width: 48%;
      }
    }
  }
  .format-quote {
    blockquote {
      .icon {
        left: -1.5em;
      }
    }
  }
  .page.page-one-column {
    .entry-header {
      margin-bottom: 4em;
    }
  }
  .full-header-page {
    .entry-header {
      margin-bottom: 4em;
    }
  }
  #comments {
    padding-top: 5em;
  }
  .comments-title {
    margin-bottom: 2.5em;
  }
  ol.children {
    .children {
      padding-left: 2em;
    }
  }
  .nav-links {
    .nav-title {
      position: relative;
    }

    .nav-previous {
      .nav-title {
        .nav-title-icon-wrapper {
          left: -2em;
        }
      }
    }

    .nav-next {
      .nav-title {
        .nav-title-icon-wrapper {
          right: -2em;
        }
      }
    }
  }
  .nav-title-icon-wrapper {
    position: absolute;
    text-align: center;
    width: 2em;
  }
  #secondary {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.6;
  }
  h2.widget-title {
    font-size: 11px;
    font-size: 0.6875rem;
    margin-bottom: 2em;
  }
  .site-footer {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.6;
    margin-top: 3em;

    .widget-column.footer-widget-1 {
      float: left;
      width: 36%;
    }

    .widget-column.footer-widget-2 {
      float: right;
      width: 58%;
    }
  }
  .social-navigation {
    clear: left;
    float: left;
    margin-bottom: 0;
    width: 36%;

    + {
      .site-info {
        margin-left: 6%;
      }
    }
  }
  .site-info {
    float: left;
    padding: 0.7em 0 0;

    .sep {
      margin: 0 0.5em;
      display: inline;
      visibility: visible;
      height: auto;
      width: auto;
    }
  }
  .gallery-columns-5 {
    .gallery-item {
      max-width: 20%;
    }
  }
  .gallery-columns-6 {
    .gallery-item {
      max-width: 16.66%;
    }
  }
  .gallery-columns-7 {
    .gallery-item {
      max-width: 14.28%;
    }
  }
  .gallery-columns-8 {
    .gallery-item {
      max-width: 12.5%;
    }
  }
  .gallery-columns-9 {
    .gallery-item {
      max-width: 11.11%;
    }
  }
}

@media screen and (min-width: 67em) {
  .navigation-top {
    .wrap {
      padding: 0.75em 2em;
    }

    nav {
      margin-left: 0;
    }
  }
  .sticky {
    .icon-thumb-tack {
      font-size: 32px;
      font-size: 2rem;
      height: 22px;
      left: -1.25em;
      top: 0.75em;
      width: 32px;
    }
  }
  .page-numbers {
    display: inline-block;
  }
  .page-numbers.current {
    font-size: 15px;
    font-size: 0.9375rem;

    .screen-reader-text {
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      overflow: hidden;
      position: absolute !important;
      width: 1px;
    }
  }
  .comment-body {
    margin-left: 0;
  }
}

@media screen and (min-width: 79em) {
  .has-sidebar {
    .entry-content {
      blockquote.alignleft {
        margin-left: -20%;
      }
    }
  }
  .blog {
    &:not(.has-sidebar) {
      .entry-content {
        blockquote.alignright {
          margin-right: -20%;
        }
      }
    }
  }
  .archive {
    &:not(.has-sidebar) {
      .entry-content {
        blockquote.alignright {
          margin-right: -20%;
        }
      }
    }
  }
  .page-two-column {
    .entry-content {
      blockquote.alignright {
        margin-right: -20%;
      }
    }
  }
  .full-header-page {
    .entry-content {
      blockquote.alignright {
        margin-right: -20%;
      }
    }
  }
}
